<template>
  <div class="deposit-info-fields-row">
    <div class="admin-info-wrap deposit-info-wrap">
      <div class="basic-select-wrap">
        <vueSelect
          :disabled="!editState"
          label="full_name"
          :reduce="id => id.id"
          :options="usersList"
          v-model="depositFieldsArr.user_id"
          placeholder="User"></vueSelect>
      </div>
      <div class="basic-select-wrap">
        <vueSelect
          :disabled="!editState"
          label="name"
          :reduce="id => id.id"
          :options="portfolioList"
          v-model="depositFieldsArr.portfolio_id"
          placeholder="Portfolio"></vueSelect>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('amount_in_currency') }"
            v-model="depositFieldsArr.amount_in_currency"
            type="number"
            placeholder="Amount in currency"
            id="deposit-info-amount">
          <label for="deposit-info-amount">Amount in currency</label>
        </div>
      </div>
    </div>

    <div class="admin-info-wrap deposit-info-wrap">
      <div class="basic-select-wrap">
        <vueSelect
          :disabled="!editState"
          label="name"
          :reduce="value => value.value"
          :options="paymentThrough"
          v-model="depositFieldsArr.payment_through"
          placeholder="Payment through"></vueSelect>
      </div>
      <div class="basic-select-wrap">
        <vueSelect
          :disabled="!editState"
          label="name"
          :reduce="value => value.value"
          :options="statusList"
          v-model="depositFieldsArr.status"
          placeholder="Status"></vueSelect>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('compound_rate') }"
            v-model="depositFieldsArr.compound_rate"
            type="text"
            placeholder="Compound rate"
            id="deposit-info-compound_rate">
          <label for="deposit-info-compound_rate">Compound rate</label>
        </div>
      </div>
    </div>

    <div class="admin-info-wrap deposit-info-wrap">
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('transaction_id') }"
            v-model="depositFieldsArr.transaction_id"
            type="text"
            placeholder="Transaction ID"
            id="deposit-info-transaction_id">
          <label for="deposit-info-transaction_id">Transaction ID</label>
        </div>
      </div>
      <div class="basic-form-wrap basic-input-wrap">
        <div class="basic-form-field-block">
          <input
            :disabled="!editState"
            :class="{ error: checkInputHasError('contract_id') }"
            v-model="depositFieldsArr.contract_id"
            type="text"
            placeholder="Contract ID"
            id="deposit-info-contract_id">
          <label for="deposit-info-contract_id">Contract ID</label>
        </div>
      </div>
    </div>

    <div class="admin-info-wrap deposit-info-wrap">
      <div class="basic-checkbox-wrap">
        <label class="basic-checkbox-container">
          <input
            checked
            :disabled="!editState"
            type="checkbox"
            class="basic-checkbox"
            v-model="depositFieldsArr.is_contract_signed">
          <span class="checkbox-text">Is contract signed</span>
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="basic-checkbox-wrap">
        <label class="basic-checkbox-container">
          <input
            checked
            type="checkbox"
            :disabled="!editState"
            class="basic-checkbox"
            v-model="depositFieldsArr.is_payment_confirmed">
          <span class="checkbox-text">Is payment confirmed</span>
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <ValidateErrors :fieldsErrors="validationErrors.errors"/>
  </div>
</template>

<script>
import vueSelect from "vue-select"
import {mapGetters} from "vuex"
import checkInputHasError from "@/mixins/checkInputHasError"
import ValidateErrors from "@/components/basic/ValidateErrors"

export default {
  name: "DepositFieldsManage",
  components: {
    ValidateErrors,
    vueSelect,
  },
  mixins: [
    checkInputHasError,
  ],
  props: [
    'editState'
  ],
  computed: {
    ...mapGetters([
      'depositFieldsArr',
      'validationErrors',
      'usersList',
      'portfolioList',
    ]),
  },
  data() {
    return {
      paymentThrough: [
        {name: 'Cryptocurrency', value: 'cryptocurrency'},
        {name: 'Bank Transfer', value: 'bank_transfer'},
      ],
      statusList: [
        {name: 'Pending', value: 'pending'},
        {name: 'Active', value: 'active'},
        {name: 'Matured', value: 'matured'},
        {name: 'Canceled', value: 'canceled'},
      ]
    }
  },
}
</script>
