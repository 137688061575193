<template>
  <ul>
    <li @click="$emit('showMoreInfo')">
      More Info
    </li>
<!--    <li @click="$emit('deleteDepositItem')">-->
<!--      Delete-->
<!--    </li>-->
  </ul>
</template>

<script>
export default {
  name: "AdminDepositTableDropdown",
}
</script>
