<template>
  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="admin-info-pop_up-container pop_up-container deposit-info-pop_up-container">
    <div class="pop_up-wrap scroll-container">
      <div class="pop_up-info-row deposit-info-row">
        <div class="pop_up-info-head deposit-info-head">
          <div class="pop_up-info-head-left deposit-info-head-left">
            <button @click="createDeposit()" class="basic_green-btn">Create</button>
          </div>
          <div class="deposit-info-head-right">
            <button @click="$emit('closeCreateDepositPopUp')" class="reset_btn">Close</button>
          </div>
        </div>

        <div class="deposit-info-content">
          <DepositFieldsManage :editState="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkWindowWidth from "@/mixins/checkWindowWidth"
import {CREATE_DEPOSIT_ITEM} from "@/store/actions"
import {mapGetters} from "vuex"
import DepositFieldsManage from "@/components/dashboard/admin/deposits/DepositFieldsManage"

export default {
  name: "AdminCreateDepositInfoPopUp",
  components: {
    DepositFieldsManage,
  },
  mixins:[
    checkWindowWidth,
  ],
  computed: {
    ...mapGetters([
      'depositFieldsArr',
      'validationErrors',
    ]),
  },
  methods:{
    async createDeposit(){
      let additionalFetchInfo = this.depositFieldsArr

      this.$store.dispatch(CREATE_DEPOSIT_ITEM, this.depositFieldsArr).then(() => {
        if(!this.validationErrors.errors){
          this.$emit('fetchDepositItems', additionalFetchInfo)
          this.$emit('closeCreateDepositPopUp')
        }
      }).catch(() => {
        //
      }).finally(() => {
        //
      })
    },
  }
}
</script>
