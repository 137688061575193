<template>
  <div class="admin-table admin-deposit-table" :class="{full: depositItemsMeta.last_page === 1}">
    <div class="admin-table-row admin-deposit-table-row">
      <p v-if="loader" class="loader"></p>
      <div class="table-head-row admin-deposit-container-table-wrap admin-deposit-table-head-row">
        <div class="table-line-wrap admin-deposit-table-line-wrap">
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-user-line">
            <span>User</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-plan-line">
            <span>Plan</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-amount-line">
            <span>Amount</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-compound-line">
            <span>Compound Rate</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-payment-line">
            <span>Payment Method</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-date-line">
            <span>Date</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-status-line">
            <span>Status</span>
          </div>
          <div class="table-line table-head-line admin-deposit-table-line admin-deposit-table-head-line admin-deposit-table-dropdown-line"></div>
        </div>
      </div>
      <div id="deposit-table-main" class="admin-table-scroll-container admin-deposit-table-scroll-container scroll-container">
        <div v-if="depositItems.length > 0" class="table-row table-content-row deposit-table-row deposit-table-content-row">
          <div
            class="table-line-wrap deposit-table-line-wrap"
            v-for="deposit in depositItems"
            :key="deposit.id">
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-user-line">
              <span>{{isEmptyField(deposit.user, 'full_name')}}</span>
            </div>
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-plan-line">
              <span>{{isEmptyField(deposit.portfolio, 'name')}}</span>
            </div>
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-amount-line">
              <span>Full: <strong>{{isEmptyField(deposit, 'full_amount_in_currency')}} {{isEmptyField(deposit, 'currency')}}</strong></span>
              <span>Compound: <strong>{{isEmptyField(deposit, 'compound_amount')}} {{isEmptyField(deposit, 'currency')}}</strong></span>
            </div>
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-compound-line">
              <span>{{isEmptyField(deposit, 'compound_rate')}}</span>
            </div>
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-payment-line">
              <span>{{isEmptyField(deposit, 'payment_through').replaceAll('_', ' ')}}</span>
            </div>
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-date-line">
              <span>Investment: <strong>{{isEmptyField(deposit, 'investment_date').slice(0, -3)}}</strong></span>
              <span>Maturity: <strong>{{isEmptyField(deposit, 'maturity_date').slice(0, -3)}}</strong></span>
            </div>
            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-status-line">
              <span :class="deposit.status">{{isEmptyField(deposit, 'status').replace('_', ' ')}}</span>
            </div>



            <div class="table-line table-content-line admin-deposit-table-line admin-deposit-table-content-line admin-deposit-table-dropdown-line">
              <DropdownList>
                <AdminDepositTableDropdown
                  :deposit="deposit"
                  @fetchDepositItems="handleFetchDepositItems"
                  @showMoreInfo="showMoreInfo(deposit.id)"/>
<!--                @deleteDepositItem="prepareDepositItemToDelete(deposit.id)"-->
              </DropdownList>
            </div>
          </div>

          <AdminDepositInfoPopUp
            @fetchDepositItems="handleFetchDepositItems"
            @closePopUp="closeDepositInfo()"
            v-if="showMoreInfoPopUp"
            @closeDepositInfo="closeDepositInfo()"/>
        </div>

        <div v-else class="no-result-container">
          <p>DEPOSITS TABLE IS EMPTY</p>
        </div>
      </div>
      <ThePagination
        v-if="depositItemsMeta"
        :currentPage="depositItemsMeta.current_page"
        :totalPages="depositItemsMeta.last_page"
        :maxPageNumbers="5"
        @pageChanged="onPageChanged"/>


<!--      <ConfirmActionPopUp-->
<!--        v-if="confirmActionPopUpState"-->
<!--        description="Do you want delete this item?"-->
<!--        @confirmAction="confirmAction('deleteDepositItem')"-->
<!--        @discardAction="confirmActionPopUpState = false"/>-->
    </div>
  </div>
</template>

<script>
import DropdownList from "@/components/basic/DropdownList"
import ThePagination from "@/components/basic/ThePagination"
import {mapGetters} from "vuex"
import {
  DELETE_DEPOSIT_ITEM,
  FETCH_DEPOSIT_ITEM, FETCH_PORTFOLIOS_LIST,
} from "@/store/actions"
// import ConfirmActionPopUp from "@/components/basic/popups/ConfirmActionPopUp"
import confirmAction from "@/mixins/confirmAction"
import isEmptyField from "@/mixins/isEmptyField"
import {
  RESET_DEPOSIT_ITEM,
  RESET_VALIDATION_ERROR,
} from "@/store/mutations"
import gmtTimezones from "@/mixins/gmtTimezones"
import countryInfo from "@/mixins/countryInfo"
import AdminDepositTableDropdown from "@/components/basic/dropdowns/AdminDepositTableDropdown"
import AdminDepositInfoPopUp from "@/components/basic/popups/AdminDepositInfoPopUp"

export default {
  name: "AdminDepositTable",
  components: {
    AdminDepositInfoPopUp,
    AdminDepositTableDropdown,
    // ConfirmActionPopUp,
    ThePagination,
    DropdownList,
  },
  props:[
    'loader'
  ],
  mixins:[
    confirmAction,
    isEmptyField,
    gmtTimezones,
    countryInfo,
  ],
  data(){
    return{
      showMoreInfoPopUp: false,
      depositID: null,
      // loader: false,
    }
  },
  computed: {
    ...mapGetters([
      'depositItems',
      'depositItemsMeta',
      'depositItemsFilter',
    ]),
  },
  methods:{
    timestampToFormattedDate (timestamp, getUTC = false, placeholder = '') {
      if (!timestamp) return placeholder
      let newDate, dateString
      let year, month, date, hours, minutes
      newDate = new Date(timestamp * 1000)

      if (getUTC) {
        year = newDate.getUTCFullYear()
        month = newDate.getUTCMonth() + 1
        date = newDate.getUTCDate()
        hours = newDate.getUTCHours()
        minutes = newDate.getUTCMinutes()
        // seconds = newDate.getUTCSeconds()
      } else {
        year = newDate.getFullYear()
        month = newDate.getMonth() + 1
        date = newDate.getDate()
        hours = newDate.getHours()
        minutes = newDate.getMinutes()
        // seconds = newDate.getSeconds()
      }

      // month = HelperFunctions.getMonthShortName(month)

      let amPm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes

      dateString =
        // ('0' + date).slice(-2) + ' ' +
        // month + ' ' +
        ('0' + date).slice(-2) + '.' +
        ('0' + month).slice(-2) + '.' +
        year + ' ' +
        hours + ':' + minutes + ' ' + amPm
      // ('0' + hours).slice(-2) + ':' +
      // ('0' + minutes).slice(-2)
      // ('0' + seconds).slice(-2) + ' - ' +

      return dateString
    },
    // prepareDepositItemToDelete(id){
    //   this.depositID = id
    //   this.confirmActionPopUpState = true
    // },
    // async deleteDepositItem(){
    //   await this.$store.dispatch(DELETE_DEPOSIT_ITEM, this.depositID)
    //   this.$emit('fetchDepositItems')
    // },

    onPageChanged(pageNumber) {
      this.depositItemsFilter.page = pageNumber
      this.$emit('fetchDepositItems')
    },

    handleFetchDepositItems(depositItemFields) {
      this.$emit('fetchDepositItems', depositItemFields)
    },

    async showMoreInfo(id){
      await this.$store.dispatch(FETCH_DEPOSIT_ITEM, id)
      await this.$store.dispatch(FETCH_PORTFOLIOS_LIST)
      this.showMoreInfoPopUp = true
    },
    closeDepositInfo(){
      this.$store.commit(RESET_DEPOSIT_ITEM)
      this.$store.commit(RESET_VALIDATION_ERROR)
      this.showMoreInfoPopUp = false
    }
  }
}
</script>
