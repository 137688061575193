<template>
  <div :class="{'pop_up-container_desktop': isDesktopWindowWidth(961)}" class="pop_up-container admin-info-pop_up-container">
    <div class="pop_up-wrap scroll-container">
      <div class="pop_up-info-row deposit-info-row">
        <div class="pop_up-info-head deposit-info-head">
          <div class="pop_up-info-head-left deposit-info-head-left">
            <button @click="editDepositItem(depositFieldsArr.id)" class="basic_green-btn">
              <template v-if="editState">Save</template>
              <template v-else>Edit</template>
            </button>

<!--            <button @click="prepareDepositItemToDelete(depositFieldsArr.id)" class="reset_btn">Delete</button>-->

<!--            <ConfirmActionPopUp-->
<!--              v-if="confirmActionPopUpState"-->
<!--              description="Do you want delete this item?"-->
<!--              @confirmAction="confirmAction('deleteDepositItem')"-->
<!--              @discardAction="confirmActionPopUpState = false"/>-->
          </div>
          <div class="user-info-head-right">
            <button @click="$emit('closeDepositInfo')" class="reset_btn">Close</button>
          </div>
        </div>

        <div class="deposit-info-content">
          <DepositFieldsManage :editState="editState"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkWindowWidth from "@/mixins/checkWindowWidth"
// import ConfirmActionPopUp from "@/components/basic/popups/ConfirmActionPopUp"
import confirmAction from "@/mixins/confirmAction"
import {
  // DELETE_DEPOSIT_ITEM,
  UPDATE_DEPOSIT_ITEM,
} from "@/store/actions"
import {mapGetters} from "vuex"
import DepositFieldsManage from "@/components/dashboard/admin/deposits/DepositFieldsManage"

export default {
  name: "AdminDepositInfoPopUp",
  components: {
    DepositFieldsManage,
    // ConfirmActionPopUp,
  },
  mixins:[
    checkWindowWidth,
    confirmAction,
  ],
  computed: {
    ...mapGetters([
      'depositFieldsArr',
      'validationErrors',
    ]),
  },
  data(){
    return{
      depositID: null,
      editState: false,
    }
  },
  methods:{
    async editDepositItem(id){
      if(this.editState){
        let additionalFetchInfo = this.depositFieldsArr

        await this.$store.dispatch(UPDATE_DEPOSIT_ITEM, {
          id: id,
          params: this.depositFieldsArr
        })
        if(!this.validationErrors.errors){
          this.$emit('closePopUp')
          this.$emit('fetchDepositItems', additionalFetchInfo)
        }
      } else {
        this.editState = true
      }
    },
    // prepareDepositItemToDelete(id){
    //   this.depositID = id
    //   this.confirmActionPopUpState = true
    // },
    // async deleteDepositItem(){
    //   await this.$store.dispatch(DELETE_DEPOSIT_ITEM, this.depositID)
    //   this.$emit('closePopUp')
    //   this.$emit('fetchDepositItems')
    // },
  }
}
</script>
