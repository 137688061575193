<template>
  <div class="container-table-filter deposit-container-table-filter">

    <div class="container-table-filter-row">
      <div class="container-table-filter-wrap">
        <div class="basic-multiselect-wrap">
          <vueSelect
            label="full_name"
            multiple
            :options="usersList"
            :reduce="id => id.id"
            v-model="depositItemsFilter.user"
            placeholder="User"></vueSelect>
        </div>
        <div class="basic-select-wrap">
          <vueSelect
            label="name"
            :reduce="value => value.value"
            :options="paymentThrough"
            v-model="depositItemsFilter.payment_through"
            placeholder="Payment Through"></vueSelect>
        </div>
      </div>
    </div>


    <div class="container-table-filter-btns deposit-container-table-filter-btns">
      <button
        class="basic_green-btn"
        @click="$emit('filterItems')">
        Search
      </button>
      <button
        class="reset_btn"
        @click="resetDepositItemsFilter()">
        Reset
      </button>

      <button
        class="basic_orange-btn"
        @click="$emit('calculateDepositInterests')">
        Calc Interests
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import HelperFunctions from "@/common/helperFunctions"
import vueSelect from "vue-select"

export default {
  name: "AdminDepositFilter",
  components:{
    vueSelect
  },
  computed: {
    ...mapGetters([
      'depositItemsFilter',
      'depositItemsMeta',
      'usersList',
    ]),
  },
  data(){
    return {
      // Filter data
      paymentThrough: [
        {name: 'Cryptocurrency', value: 'cryptocurrency'},
        {name: 'Bank Transfer', value: 'bank_transfer'},
      ],
    }
  },
  mounted() {
    //
  },
  methods:{
    resetDepositItemsFilter(){
      this.$store.commit('resetDepositItemsFiltersArr')
      this.$emit('filterItems')
    },
  }
}
</script>
