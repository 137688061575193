<template>
  <p v-if="status" class="status_message">
    <template v-if="validationErrors.message">{{validationErrors.message}}</template>
    <template v-else>Success</template>
  </p>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "messageAboutActionStatus",
  props:['status'],
  computed: {
    ...mapGetters([
      'validationErrors',
    ]),
  },
}
</script>
