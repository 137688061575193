<template>
  <user-dashboard-layout>
    <div class="admin-deposits-container">

      <MessageAboutActionStatus :status="calculateMessageStatus" />

      <div class="portfolios-filter admin-deposits-filter">
        <AdminDepositFilter v-if="showTableFilter" :key="filterComponentKey" @filterItems="filterDepositItems()" @calculateDepositInterests="calculateDepositInterests()"/>

        <div class="admin-deposits-new">
          <button @click="showCreateDepositPopUp()" class="basic_green-btn">New</button>

          <AdminCreateDepositInfoPopUp
            v-if="createDepositPopUpState"
            @fetchDepositItems="fetchDepositItems"
            @closeCreateDepositPopUp="closeCreateDepositPopUp()"/>
        </div>
      </div>

      <AdminDepositTable
        @fetchDepositItems="fetchDepositItems"
        :loader="loader"/>

    </div>
  </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout"
import redirectUserByRole from "@/mixins/redirectUserByRole"
import {
  FETCH_DEPOSIT_ITEMS,
  FETCH_PORTFOLIOS_LIST,
  FETCH_USERS_LIST,
  CALCULATE_DEPOSIT_INTERESTS,
} from "@/store/actions"
import {mapGetters} from "vuex"
import {
  RESET_DEPOSIT_ITEM,
  RESET_VALIDATION_ERROR,
} from "@/store/mutations"
import AdminDepositFilter from "@/components/dashboard/admin/deposits/AdminDepositFilter"
import AdminCreateDepositInfoPopUp from "@/components/basic/popups/AdminCreateDepositPopUp"
import AdminDepositTable from "@/components/dashboard/admin/deposits/AdminDepositTable"
import MessageAboutActionStatus from "@/components/basic/MessageAboutActionStatus"

export default {
  name: "AdminDeposits",
  components:{
    MessageAboutActionStatus,
    AdminDepositTable,
    AdminCreateDepositInfoPopUp,
    AdminDepositFilter,
    UserDashboardLayout,
  },
  mixins: [
    redirectUserByRole,
  ],
  computed: {
    ...mapGetters([
      'depositItems',
      'depositItemsMeta',
      'depositItemsFilter',
      'validationErrors',
    ]),
    showTableFilter: function () {
      return !this.depositItemsMeta.is_totally_empty
    },
  },
  async beforeRouteLeave (to, from, next) {
    await this.$store.commit(RESET_VALIDATION_ERROR)
    await this.$store.commit('resetDepositItemsFiltersArr')
    next()
  },
  data(){
    return{
      filterComponentKey: null,
      createDepositPopUpState: false,
      loader: false,
      calculateMessageStatus: false,
    }
  },
  mounted() {
    this.fetchDepositItems()
    this.fetchUsersList()
  },
  methods:{
    calculateDepositInterests(){
      this.loader = true
      this.$store.dispatch(CALCULATE_DEPOSIT_INTERESTS).then(() => {
        //
      }).finally(() => {
        this.loader = false
        this.calculateMessageStatus = true
        setTimeout(()=>{
          this.calculateMessageStatus = false
        },3000)
      })
    },
    fetchUsersList(){
      this.$store.dispatch(FETCH_USERS_LIST)
    },
    async fetchDepositItems(additionalData){
      this.loader = true
      await this.$store.dispatch(FETCH_DEPOSIT_ITEMS, this.depositItemsFilter).then(() => {
        //
      }).finally(() => {
        this.filterComponentKey = this.$helpers.uniqueId()
        this.loader = false
      })
    },
    filterDepositItems(){
      this.depositItemsFilter.page = 1
      this.fetchDepositItems()
    },
    async showCreateDepositPopUp(){
      await this.$store.dispatch(FETCH_PORTFOLIOS_LIST)
      this.createDepositPopUpState = true
    },
    closeCreateDepositPopUp(){
      this.$store.commit(RESET_DEPOSIT_ITEM)
      this.$store.commit(RESET_VALIDATION_ERROR)
      this.createDepositPopUpState = false
    }
  }
}
</script>
